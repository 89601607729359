import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

// components
import Container from '../components/Container';

// styles
import styles from '../css/templates/gemstone.module.css';

export default ({ data, location }) => {
  const name = data.datoCmsGemstone.name;
  const subtitle = data.datoCmsGemstone.subtitle;
  const image = data.datoCmsGemstone.featuredImage.fluid;
  const descriptionHtml = data.datoCmsGemstone.originNode.childMarkdownRemark.html;

  return (
    <Container title={name} permalink={location.pathname + '/'}>
      <div className="wrap">
        <article className={styles.gemstone}>
          {/* image */}
          <div className={styles.image}>
            <Img fluid={image} />
          </div>

          {/* body */}
          <div className={styles.body}>
            <div className={styles.header}>
              <h2 className={styles.name}>{name}</h2>
              <div className={styles.subtitle}>{subtitle}</div>
              <div
                className={styles.origin}
                dangerouslySetInnerHTML={{ __html: descriptionHtml }}
              />
            </div>
          </div>
        </article>
      </div>
    </Container>
  );
};

export const query = graphql`
  query($slug: String!) {
    datoCmsGemstone(slug: { eq: $slug }) {
      id
      name
      slug
      subtitle
      originNode {
        childMarkdownRemark {
          html
        }
      }
      featuredImage {
        fluid(maxWidth: 450, maxHeight: 500, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`;
